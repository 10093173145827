<template>
  <el-dialog customClass="EquipmentDialogCURD" :visible.sync="isVisible" :destroy-on-close="true" :show-close="true" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false">
    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-30">{{ dialogTitle }}</h5>
      <vs-divider></vs-divider>
    </span>

    <!-- 弹窗内容 -->
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">
      <el-row :gutter="24">
        <el-col :span="8">
          <!-- 表单认证(设备名称) -->
          <el-form-item label="设备名称" prop="deviceName">
            <el-input v-model="form.deviceName" prefix-icon="el-icon-edit" placeholder="请输入设备名称" :disabled="isDisable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 表单认证(设备类型) -->
          <el-form-item label="设备类型" prop="deviceTypeCode">
            <el-select v-model="form.deviceTypeCode" placeholder="请选择设备类型" class="w-full" :disabled="isDisable || isDisableBrandAndModelAndTypeCode">
              <el-option v-for="item in deviceTypeCodeOptions" :key="item.order" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 表单认证(设备厂商) -->
          <el-form-item label="设备厂商+型号" prop="productBrandAndModel">
            <el-cascader v-if="reFresh" v-model="form.productBrandAndModel" :placeholder="productBrandAndModelPlaceholder" :props="productBrandAndModelOptions" class="w-full" :disabled="isDisable || isDisableBrandAndModelAndTypeCode"></el-cascader>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <!-- 表单认证(IP) -->
          <el-form-item label="设备IP" prop="ip">
            <el-input v-model="form.extend.ip" prefix-icon="el-icon-edit" placeholder="请输入设备IP" :disabled="isDisable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 表单认证(端口) -->
          <el-form-item label="设备端口" prop="port">
            <el-input v-model="form.extend.port" prefix-icon="el-icon-edit" placeholder="请输入设备端口" :disabled="isDisable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 表单认证(设备MAC) -->
          <el-form-item label="设备MAC" prop="mac">
            <el-input v-model="form.extend.mac" prefix-icon="el-icon-edit" placeholder="请输入设备MAC" :disabled="isDisable"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8">
          <!-- 表单认证(安装时间) -->
          <el-form-item label="安装时间" prop="installTime">
            <el-date-picker v-model="form.installTime" type="datetime" placeholder="请选择安装时间" align="right" :picker-options="pickerOptions" :disabled="isDisable" style="width:100%"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 表单认证(安装地址) -->
          <el-form-item label="安装地址" prop="installAddr">
            <el-input v-model="form.installAddr" prefix-icon="el-icon-edit" placeholder="请输入安装地址" :disabled="isDisable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 表单认证(是否启用) -->
          <el-form-item label="是否启用" prop="enable">
            <el-switch v-model="form.enableDevice" active-color="#13ce66" inactive-color="#ff4949" :disabled="isDisable"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-divider content-position="left">特有属性</el-divider>

      <el-row :gutter="24">
        <el-col :span="8">
          <!-- 表单认证(国标编码) -->
          <el-form-item label="国标编码" prop="countryCode">
            <el-input v-model="form.extend.countryCode" prefix-icon="el-icon-date" placeholder="请输入国标编码" :disabled="isDisable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <!-- 表单认证(IMEI) -->
          <el-form-item label="IMEI" prop="imei">
            <el-input v-model="form.extend.imei" prefix-icon="el-icon-date" placeholder="请输入IMEI" :disabled="isDisable"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8" v-if="form.deviceType == 'access' || form.deviceType == 'camera'">
          <!-- 表单认证(方位) -->
          <el-form-item label="方位" prop="direction">
            <el-select v-model="form.extend.direction" placeholder="请选择方位" class="w-full" :disabled="isDisable">
              <el-option v-for="item in deviceDirectionOptions" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="24" v-if="form.deviceType == 'camera'">
          <!-- 表单认证(协议类型) -->
          <el-form-item label="协议类型" prop="rtsp">
            <el-input v-model="form.extend.rtsp" prefix-icon="el-icon-date" placeholder="请输入RTSP地址" :disabled="isDisable"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24">
        <el-col :span="8" v-if="form.deviceType == 'access'">
          <!-- 表单认证(协议类型) -->
          <el-form-item label="协议类型" prop="protocal">
            <el-select v-model="form.protocal" placeholder="请选择协议类型" class="w-full" :disabled="isDisable">
              <el-option v-for="item in productBrandAndProtocalOptions" :key="item.order" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <!-- 弹窗底部 -->
    <span slot="footer" class="dialog-footer">
      <vs-divider></vs-divider>
      <vs-button size="small" type="relief" color="grey" @click="handleClose" class="mr-5">取 消</vs-button>
      <vs-button size="small" type="relief" color="danger" @click="delEquipment" class="mr-5 vs-con-loading__container" v-if="isDisable && this.operate == 'DELETE'">删除</vs-button>
      <vs-button size="small" type="relief" color="primary" @click="submitForm('ruleForm')" class="vs-con-loading__container" v-if="!isDisable">确 定</vs-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ['dialogFormVisible', 'dialogFormData', 'dialogFormOpt', 'deviceType'],
  data() {
    return {
      reFresh: false,
      dialogTitle: '',
      operate: this.dialogFormOpt,
      isVisible: this.dialogFormVisible,
      isDisable: false,
      isDisableBrandAndModelAndTypeCode: false,
      productBrandAndModelPlaceholder: '请选择设备厂商+型号',
      form: {
        villageCode: '',
        deviceCode: '',
        productBrandAndModel: [],
        protocal: 'owner',
        deviceTypeCode: '',
        deviceType: this.deviceType,
        deviceTypeName: '',
        deviceName: '',
        productModel: '',
        installTime: null,
        installAddr: '',
        productBrand: '',
        productModel: '',
        enableDevice: true,
        enable: 1,
        extend: {
          countryCode: '',
          imei: '',
          ip: '',
          mac: '',
          port: '',
          inoutType: '',
          rtsp: '',
          direction: 0
        }
      },
      deviceTypeCodeOptions: [],
      deviceDirectionOptions: [
        { name: '未知', value: 0 },
        { name: '进', value: 1 },
        { name: '出', value: 2 }
      ],
      productBrandAndProtocalOptions: [],
      productBrandAndModelOptions: {
        lazy: true,
        lazyLoad: this.cascaderLazyLoad,
        data: []
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            }
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            }
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            }
          }
        ]
      },
      rules: {
        deviceName: [{ required: true, message: '请输入设备名称', trigger: 'blur' }],
        deviceTypeCode: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
        productBrandAndModel: [{ required: true, message: '请选择设备厂商+型号', trigger: 'change' }]
      }
    }
  },
  computed: {},
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      //console.log("dialogFormData", val);
      this.reFresh = false
      // 设备所需参数
      this.form = this.decodeParam(val)
      this.productBrandAndModelPlaceholder = this.form.productBrandAndModel.length > 0 ? this.form.productBrandAndModel.join(' / ') : '请选择设备厂商+型号'
      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true
      })
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.changeNavBarShadow(val)
      this.isVisible = this.dialogFormVisible
    },
    // 监听父组件传回来的值 - CURD操作值
    dialogFormOpt(val) {
      this.operate = this.dialogFormOpt
      switch (val) {
        case 'CREATE':
          this.dialogTitle = '创建设备'
          this.isDisable = false
          this.isDisableBrandAndModelAndTypeCode = false
          break
        case 'READ':
          this.dialogTitle = '查看设备'
          this.isDisable = true
          this.isDisableBrandAndModelAndTypeCode = true
          break
        case 'UPDATE':
          this.dialogTitle = '更新设备'
          this.isDisable = false
          this.isDisableBrandAndModelAndTypeCode = true
          break
        case 'DELETE':
          this.dialogTitle = '删除设备'
          this.isDisable = true
          this.isDisableBrandAndModelAndTypeCode = true
          break
      }
    },
    // 监听设备类型 修改情况厂商和型号 (修改不触发)
    'form.deviceTypeCode'(val) {
      this.reFresh = false
      if (this.isDisableBrandAndModelAndTypeCode == false) {
        this.form.productBrandAndModel = []
      }
      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true
      })
    }
  },
  methods: {
    // 添加设备
    async addEquipment() {
      try {
        let params = this.encodeParam(this.form)
        let result = await this.$apis.ODC('添加设备', params)
        if (this.$apis.isOk(result)) {
          this.showNotify('success', this.$apis.getResMessage(result))
          this.handleClose(this, true)
        } else {
          this.showNotify('warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.showNotify('danger', err.message ? err.message : err)
      }
    },
    // 修改设备
    async updateEquipment() {
      try {
        let params = this.encodeParam(this.form)
        let result = await this.$apis.ODC('更新设备', params)
        if (this.$apis.isOk(result)) {
          this.showNotify('success', this.$apis.getResMessage(result))
          this.handleClose(this, true)
        } else {
          this.showNotify('warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.showNotify('danger', err.message ? err.message : err)
      }
    },
    // 删除设备
    async delEquipment() {
      try {
        let params = {
          deviceCode: this.form.deviceCode
        }
        let result = await this.$apis.ODC('删除设备', params)
        if (this.$apis.isOk(result)) {
          this.showNotify('success', this.$apis.getResMessage(result))
          this.handleClose(this, true)
        } else {
          this.showNotify('warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.showNotify('danger', err.message ? err.message : err)
      }
    },
    // 修改提交参数
    encodeParam(form) {
      form.deviceTypeName = _.find(this.deviceTypeCodeOptions, ['code', this.form.deviceTypeCode]).type_name
      ;(form.productBrand = form.productBrandAndModel[0]), (form.productModel = form.productBrandAndModel[1]), (form.enable = form.enableDevice ? 1 : 0)
      return form
    },
    // 恢复提交参数
    decodeParam(val) {
      let form = JSON.parse(JSON.stringify(this.form))
      for (var key in form) {
        // device_type_code  变 deviceTypeCode
        // let spliceKey = key.split("_").map((item, index) => { return index > 0 ? `${item.slice(0, 1).toUpperCase()}${item.slice(1)}` : item }).join("");
        // deviceTypeCode 变 device_type_code
        let spliceKey = (key.slice(0, 1).toLowerCase() + key.slice(1)).replace(/[A-Z]/g, item => {
          return '_' + item.toLowerCase()
        })
        if (val[key] || val[spliceKey]) form[key] = val[key] || val[spliceKey]
      }
      form.villageCode = val.villageCode
      form.deviceTypeCode = val.device_code ? val.device_code.slice(15, 18) : ''
      form.productBrandAndModel = val.product_model ? [val.product_brand, val.product_model] : []
      form.enableDevice = val.enable == 0 ? false : true
      return form
    },
    // 获取厂商和型号
    async cascaderLazyLoad(node, resolve) {
      var nodes = ''
      const { level, value } = node
      if (level === 0) {
        let parentList = this.productBrandAndModelOptions.data
        nodes = parentList.map(item => ({
          value: item.code,
          label: item.name,
          leaf: level >= 1
        }))
      } else {
        let checkdeviceType = _.find(this.deviceTypeCodeOptions, { code: this.form.deviceTypeCode })
        let checkdeviceTypeName = checkdeviceType ? checkdeviceType.type_name : ''
        let parentList = await this.getRelationReturnData(`db/device/productBrand/${this.form.deviceType}/${node.value}/model/${checkdeviceTypeName}`)
        nodes = parentList.map(item => ({
          value: item.code,
          label: item.name,
          leaf: level >= 1
        }))
      }
      resolve(nodes)
    },
    // 获取关系内容
    async getRelation(usingTypes) {
      this.loading = true
      try {
        let params = {
          usingTypes: usingTypes
        }
        let result = await this.$apis.ODC('获取关系内容', params)
        if (this.$apis.isOk(result)) {
          result.relationList.forEach(elem => {
            if (elem.using_type == `db/device/productBrand/${this.form.deviceType}`) {
              this.productBrandAndModelOptions.data = elem.extend
            } else if (elem.using_type == `db/device/main_type/${this.form.deviceType}`) {
              this.deviceTypeCodeOptions = elem.extend
            } else if (elem.using_type == `db/device/productBrand/${this.form.deviceType}/protocal`) {
              this.productBrandAndProtocalOptions = elem.extend
            }
          })
        } else {
          this.showNotify('warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.showNotify('danger', err.message ? err.message : err)
      }
      this.loading = false
    },
    // 获取关系内容并直接返回 -> 一个一个传
    async getRelationReturnData(usingType) {
      try {
        let params = {
          usingTypes: [usingType]
        }
        let result = await this.$apis.ODC('获取关系内容', params)
        if (this.$apis.isOk(result)) {
          return result.relationList[0].extend
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget
      this.$refs[formName].validate(async valid => {
        if (valid) {
          // 开启loading
          this.$cFun.openDomLoad(this.$vs.loading, el)
          // 判断下操作
          if (this.operate === 'CREATE') {
            await this.addEquipment()
          } else if (this.operate === 'UPDATE') {
            await this.updateEquipment()
          }
        } else {
          return false
        }
      })
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el)
      }, 1000)
    },
    // 删除事件
    async delForm() {
      let el = event.currentTarget
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el, 'danger')
      // 提交API删除角色
      await this.delEquipment()
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el)
      }, 1000)
    },
    // 关闭弹框回调
    handleClose(done, isRefresh = false) {
      this.isVisible = false
      if (this.isVisible == false) {
        this.resetForm('ruleForm')
        this.$emit('handleDiaLogClose', isRefresh)
      }
    },
    // 导航栏阴影问题 组件联动 (true, false)
    changeNavBarShadow(val) {
      var dom = document.getElementById('content-area')
      if (dom) {
        if (val) {
          dom.setAttribute(
            'class',
            dom
              .getAttribute('class')
              .concat(' ')
              .concat('dialog-show-overlay')
          )
        } else {
          dom.setAttribute(
            'class',
            dom
              .getAttribute('class')
              .replace(' ', '')
              .replace('dialog-show-overlay', '')
          )
        }
      }
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = ''
      switch (state) {
        case 'success':
          title = '成功'
          color = 'success'
          break
        case 'danger':
          title = '错误'
          color = 'danger'
          break
        case 'warning':
          title = '警告'
          color = 'warning'
          break
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: color
      })
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.emptyData()
    },
    // 清空数据
    emptyData() {
      this.form.deviceCode = ''
      this.form.extend = { countryCode: '', imei: '', ip: '', mac: '', port: '', inoutType: '', direction: 0, rtsp: '' }
    }
  },
  created() {
    this.getRelation([`db/device/productBrand/${this.form.deviceType}`, `db/device/main_type/${this.form.deviceType}`, `db/device/productBrand/${this.form.deviceType}/protocal`])
  },
  destroyed() {
    this.changeNavBarShadow()
  },
  components: {}
}
</script>

<style lang="scss" scope="scope">
.EquipmentDialogCURD {
  width: 50%;
  min-width: 350px;
}
</style>
