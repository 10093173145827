<!-- eslint-disable prettier/prettier -->
<!-- 
   页面  设备列表 (这里的Table 用了 elemnt-ui)
-->
<template>
  <div id="collect-EquipmentList-page" class="data-list-container dark-layout">
    <vx-card>
      <div class="table_height_container">
        <!-- 操作按钮 -->
        <div class="operate-container mb-3">
          <vs-button size="small" type="relief" class="mr-4" @click="$router.back(-1)">返回</vs-button>
          <vs-button size="small" type="relief" class="mr-4" @click="createEquipment">新增设备</vs-button>
          <vxFileXml :deviceType="deviceType" @handleDiaLogClose="handleDiaLogClose(arguments)"></vxFileXml>
        </div>
        <!-- 整体表 -->
        <el-table class="table_theme" :data="deviceList" ref="deviceListTable" v-loading="loading">
          <el-table-column type="index" width="60" label="序号">
            <template slot-scope="scope">
                <span>{{(pageOpt.currentPage - 1) * pageOpt.currentPageSize + scope.$index + 1}}</span>
            </template>
          </el-table-column>
          <el-table-column property="device_name" label="设备名称" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column label="设备类型" width="180" show-overflow-tooltip>
             <template slot-scope="scope">
                <span>{{ deviceDetailType(scope.row.device_code) }}</span>
            </template>
          </el-table-column>
          <el-table-column property="device_code" label="设备编码" show-overflow-tooltip></el-table-column>
            <el-table-column prop="name" label="启用" width="75" header-align="center" align="center">
              <template slot-scope="scope">
                <i v-if="scope.row.enable == 1" class="el-icon-check"></i>
                <i v-if="scope.row.enable == 0" class="el-icon-close"></i>
              </template>
            </el-table-column>
          <el-table-column fixed="right" label="操作" width="120" header-align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" class="primary" @click="watchData(scope.row)">详情</el-button>
              <el-button type="text" size="small" class="primary" @click="editData(scope.row)">编辑</el-button>
              <el-button type="text" size="small" class="primary" @click="delData(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="bottom mt-5 text-center">
          <el-pagination  class="pagination" layout="total, sizes, prev, pager, next"  prev-text="上一页" next-text="下一页"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageOpt.currentPage"
            :page-sizes="pageOpt.pageSizes"
            :page-size="pageOpt.currentPageSize"
            :pager-count="pageOpt.pageCount"
            :total="pageOpt.totalPageSize"
            :hide-on-single-page="pageOpt.hideSinglePage"
          ></el-pagination>
        </div>
      </div>
    </vx-card>

    <!-- 弹出框 -->
    <equipmentDialogCURD :deviceType="deviceType" :dialogFormOpt="dialogFormOpt" :dialogFormData="dialogFormData" :dialogFormVisible="dialogFormVisible"  @handleDiaLogClose="handleDiaLogClose(arguments)"></equipmentDialogCURD>
  </div>
</template>

<script>
// 导入弹框
import equipmentDialogCURD from "./EquipmentDialogCURD";
import vxFileXml from "@/components/VxFileXml";

export default {
  data() {
    return {
      // 选中数据
      selected: [],
      // 渲染数据
      villageCode: "",
      deviceType: "",
      deviceList: [],
      relationReturnDataAll: [],
      // 加载状态
      loading: false,
      // 弹出框状态
      dialogFormVisible: false,
      // 弹出框所需数据
      dialogFormData: {},
      // 弹出框所做的操作: CRUD
      dialogFormOpt: "",
      // 当前页
      pageOpt: {
        currentPage: 1,
        currentPageSize: 10,
        totalPageSize: 0,
        hideSinglePage: true,
        pageSizes: [10, 20, 30],
        pageCount: 5
      }
    };
  },
  watch: {
    "$store.state.auth.space.space_code"(val) {
        this.villageCode = val;
        this.getDeviceList();
    },
  },
  methods: {
    // 新增设备
    createEquipment() {
      this.dialogFormOpt = this.$CONST.OPTRATE.C;
      this.dialogFormVisible = true;
      this.dialogFormData = Object.assign({
        deviceType: this.deviceType,
        villageCode: this.villageCode
      });
    },
    // 查看设备
    watchData(row) {
      this.dialogFormOpt = this.$CONST.OPTRATE.R;
      this.dialogFormVisible = true;
      this.dialogFormData = Object.assign( {
          deviceType: this.deviceType,
          villageCode: this.villageCode
        }, 
        row
      );
    },
    // 编辑设备
    editData(row) {
      this.dialogFormOpt = this.$CONST.OPTRATE.U;
      this.dialogFormVisible = true;
      this.dialogFormData = Object.assign( {
        deviceType: this.deviceType,
        villageCode: this.villageCode
      },
        row
      );
    },
    // 删除设备
    delData(row) {
      this.dialogFormOpt = this.$CONST.OPTRATE.D;
      this.dialogFormVisible = true;
      this.dialogFormData = Object.assign({
          deviceType: this.deviceType,
          villageCode: this.villageCode
        },
        row
      );
    },
    // 获取设备列表
    async getDeviceList() {
      this.loading = true;
      try {
        let params = {
          villageCode: this.villageCode,
          deviceType:  _.union(_.map(this.relationReturnDataAll[0].extend, 'type_name')).join(),
          pageNum: this.pageOpt.currentPage,
          pageSize: this.pageOpt.currentPageSize
        };
        let result = await this.$apis.ODC("获取设备列表", params);
        if (this.$apis.isOk(result)) {
          this.deviceList = result.data.deviceList;
          this.pageOpt.totalPageSize = result.data.count;
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
      this.loading = false;
    },
    // 获取枚举值(全部)
    async getRelationReturnDataALL(usingTypeArr) {
      try {
        let params = {
          usingTypes: usingTypeArr
        };
        let result = await this.$apis.ODC("获取关系内容", params);
        if (this.$apis.isOk(result)) {
          return  result.relationList
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    // code 切换 设备编码
    deviceDetailType(code) {
      // 匹配16-18位
      let subCode = code.substring(15, 18).toString();
      let codeMap = [
        { code: "001", name: "普通摄像头" },
        { code: "002", name: "人脸抓拍机" },
        { code: "003", name: "监控摄像机" },
        { code: "004", name: "车辆抓拍机" },
        { code: "101", name: "单元门禁机" },
        { code: "102", name: "围墙机" },
        { code: "203", name: "烟感" },
        { code: "204", name: "电表" },
        { code: "205", name: "室外消火栓" },
        { code: "206", name: "电弧" },
        { code: "207", name: "井盖" },
        { code: "208", name: "可燃气" },
        { code: "209", name: "电子围栏" },
        { code: "210", name: "温感" },
        { code: "211", name: "地磁" },
        { code: "212", name: "水压" },
        { code: "213", name: "水质" },
        { code: "214", name: "手环" },
        { code: "215", name: "环境监测设备" },
        { code: "216", name: "地锁" },
        { code: "301", name: "垃圾箱" },
        { code: "302", name: "垃圾桶" },
        { code: "401", name: "电梯" },
        { code: "501", name: "人脸网关" },
        { code: "601", name: "健康一体机" }
      ];
      let matchCode = codeMap.filter(item => item.code == subCode);
      return matchCode.length > 0 ? matchCode[0].name : "未知";
    },
    // 调整单页条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageOpt.currentPageSize = val;
      this.getDeviceList();
    },
    // 调整当前页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageOpt.currentPage = val;
      this.getDeviceList();
    },
    // 加载动画
    loadAnimation() {
      this.loading = true;
      return new Promise(resolve => {
        setTimeout(() => {
          this.loading = false;
          resolve(true);
        }, 1000);
      });
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = "";
      switch (state) {
        case "success":
          title = "成功";
          color = "success";
          break;
        case "danger":
          title = "错误";
          color = "danger";
          break;
        case "warning":
          title = "警告";
          color = "warning";
          break;
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    // 当子模态框关闭回调
    handleDiaLogClose(isRefresh) {
      this.dialogFormVisible = false;
      if (isRefresh[0]) this.getDeviceList();
    }
  },
  async created() {
    // 设备类型
    this.deviceType = this.$route.params.type;
    // 获取小区code
    this.villageCode = this.$store.state.auth.space.space_code || "";
    // 获取类型小类
    this.relationReturnDataAll = await this.getRelationReturnDataALL([`db/device/main_type/${this.deviceType}`]);
    // 请求数据
    this.getDeviceList();
  },
  components: {
    equipmentDialogCURD,
    vxFileXml
  }
};
</script>

<style lang="scss" scope="scope">
#collect-EquipmentList-page {
  .table_height_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 200px);
  }
  .el-button.is-disabled.el-button--text {
    text-decoration: line-through;
  }
}
</style>
