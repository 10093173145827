<template>
  <div class="inline-block" style="position: relative;">
    <div class="inline-block fileXml">
      <input type="file" value="导入xlsx" accept=".xls,.xlsx" ref="refInput" class="upload_file" @change="readExcel($event)" />
      <vs-button size="small" type="relief" class="upload_btn">导入xlsx</vs-button>
    </div>
    <div class="inline-block ml-4">
      <vs-button size="small" type="relief" class="download_btn" @click="download_btn">下载模版</vs-button>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'

export default {
  name: 'vx-fileXml',
  props: {
    deviceType: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      deviceTypeMap: [
        { deviceType: 'access', deviceName: '门禁设备' },
        { deviceType: 'camera', deviceName: '摄像机' },
        { deviceType: 'NB', deviceName: 'NB' }
      ],
      upload_file: '',
      lists: [],
      productBrandAndModelOptions: [],
      productBrandAndProtocalOptions: [],
      deviceTypeCodeOptions: [],
      deviceDirectionOptions: [
        { name: '未知', value: 0 },
        { name: '进', value: 1 },
        { name: '出', value: 2 }
      ],
      form: {
        deviceType: this.deviceType
      }
    }
  },
  watch: {},
  methods: {
    /**
     *  睡眠函数
     *  @param numberMillis -- 要睡眠的毫秒数
     */
    sleep(numberMillis) {
      var now = new Date()
      var exitTime = now.getTime() + numberMillis
      while (true) {
        now = new Date()
        if (now.getTime() > exitTime) return
      }
    },
    // 下载
    download_btn() {
      // console.log("下载模版")
      window.location.href = '/xlsx/设备导入模版.xlsx'
    },
    // 加载
    readExcel(e) {
      console.log('开始读取execl')
      // 读取表格文件
      let that = this
      const files = e.target.files
      if (files.length <= 0) return false
      if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$cFun.showNotify(this.$vs.notify, 'warning', '上传格式不正确，请上传xls或者xlsx格式')
        return false
      }
      // 更新获取文件名
      that.upload_file = files[0].name
      const fileReader = new FileReader()
      fileReader.onload = ev => {
        try {
          const data = ev.target.result
          const workbook = XLSX.read(data, { type: 'binary' })
          const wsnames = workbook.SheetNames

          wsnames.forEach(iname => {
            // 不是指定类型不提取
            let isExistType = _.find(this.deviceTypeMap, { deviceType: this.deviceType, deviceName: iname })
            if (isExistType) {
              let ws = XLSX.utils.sheet_to_json(workbook.Sheets[iname]) //生成json表格内容
              // 从解析出来的数据中提取相应的数据
              new Promise((resolve, reject) => {
                let count = ws.length
                let currentIndex = 0

                ws.forEach(async item => {
                  // console.log('添加')
                  let isOk = await that.onloadDate(iname, item)
                  if (isOk) {
                    currentIndex++
                  }
                  // that.sleep(200)
                  // console.log(currentIndex, count)

                  if (currentIndex == count) {
                    // console.log('刷新')
                    this.$emit('handleDiaLogClose', true)
                  }
                })

                resolve()
              })
            } else {
              console.log('为检测到匹配的sheet')
            }
          })
        } catch (e) {
          console.log('解析xlsx失败:', e)
          this.$cFun.showNotify(this.$vs.notify, 'warning', '解析xlsx失败')
          return false
        }
      }

      fileReader.onerror = function(ex) {
        console.log('fileReader:', ex)
      }

      fileReader.readAsBinaryString(files[0])

      // 解决第二次同名文件上传失效问题
      this.$refs.refInput.value = null
    },
    // 处理数据
    async onloadDate(sheetName, sheetDate) {
      // console.log("sheetName:", sheetName, "sheetDate:", sheetDate)

      let villageCode = this.$store.state.auth.space.space_code
      let deviceType = this.deviceType
      let deviceTypeCodeObj = _.find(this.deviceTypeCodeOptions, { name: sheetDate['设备类型'] })
      let deviceTypeName = deviceTypeCodeObj.type_name
      let deviceTypeCode = deviceTypeCodeObj.code

      // console.log(sheetDate["设备厂商"])
      let productBrandAndModelObj = _.find(this.productBrandAndModelOptions, function(value, key) {
        return sheetDate['设备厂商'].includes(value.name)
      })

      if (!productBrandAndModelObj) {
        return false
      }

      let productBrand = productBrandAndModelObj.code

      let parentList = await this.getRelationReturnData(`db/device/productBrand/${this.deviceType}/${productBrand}/model/${deviceTypeCodeObj.type_name}`)
      let productModelObj = _.find(parentList, { name: sheetDate['设备型号'] })
      let productModel = productModelObj.code

      //console.log(sheetDate["协议类型"])
      let productBrandAndProtocalObj = _.find(this.productBrandAndProtocalOptions, function(value, key) {
        return sheetDate['协议类型'].includes(value.name)
      })

      let protocal = productBrandAndProtocalObj ? productBrandAndProtocalObj.code : ''
      let directionObj = _.find(this.deviceDirectionOptions, { name: sheetDate['方位'] })

      let deviceName = sheetDate['设备名称']
      let installTime = sheetDate['安装时间']
      let installAddr = sheetDate['安装地址']
      let enable = sheetDate['是否启用'] == '启用' ? 1 : 0
      let extend = new Object()
      extend['countryCode'] = sheetDate['国标编码']
      extend['imei'] = sheetDate['IMEI']
      extend['ip'] = sheetDate['设备IP']
      extend['mac'] = sheetDate['设备MAC']
      extend['port'] = sheetDate['设备端口']
      extend['rtsp'] = sheetDate['RTSP'] ? sheetDate['RTSP'] : ''
      extend['inoutType'] = ''
      extend['direction'] = directionObj ? directionObj.value : 0

      await this.addEquipment({ villageCode, deviceType, deviceTypeCode, deviceTypeName, deviceName, installTime, installAddr, productBrand, productModel, enable, protocal, extend })

      return true
    },
    // 添加设备
    async addEquipment(obj) {
      try {
        let params = obj
        let result = await this.$apis.ODC('添加设备', params)
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取关系内容
    async getRelation(usingTypes) {
      try {
        let params = {
          usingTypes: usingTypes
        }
        let result = await this.$apis.ODC('获取关系内容', params)
        if (this.$apis.isOk(result)) {
          result.relationList.forEach(elem => {
            if (elem.using_type == `db/device/productBrand/${this.form.deviceType}`) {
              this.productBrandAndModelOptions = elem.extend
            } else if (elem.using_type == `db/device/main_type/${this.form.deviceType}`) {
              this.deviceTypeCodeOptions = elem.extend
            } else if (elem.using_type == `db/device/productBrand/${this.form.deviceType}/protocal`) {
              this.productBrandAndProtocalOptions = elem.extend
            }
          })
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取关系内容并直接返回 -> 一个一个传
    async getRelationReturnData(usingType) {
      try {
        let params = {
          usingTypes: [usingType]
        }
        let result = await this.$apis.ODC('获取关系内容', params)
        if (this.$apis.isOk(result)) {
          return result.relationList[0].extend
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    }
  },
  mounted() {},
  created() {
    this.getRelation([`db/device/productBrand/${this.form.deviceType}`, `db/device/main_type/${this.form.deviceType}`, `db/device/productBrand/${this.form.deviceType}/protocal`])
  }
}
</script>

<style lang="scss">
.fileXml {
  width: 100px;
}
.fileXml .upload_file {
  position: absolute;
  width: 100px;
  height: 31px;
  top: 0;
  z-index: 1;
  opacity: 0;
}
.fileXml .upload_file:hover {
  cursor: pointer;
}
.fileXml .upload_btn {
  position: absolute;
  cursor: pointer;
  width: 100px;
  top: 0;
  left: 0;
}
</style>
